import React, {
  useState,
  useCallback,
  memo,
  forwardRef,
  ForwardedRef,
} from "react";
import { useUpdateEffect } from "react-use";
import {
  CircularProgress,
  Typography,
  IconButton,
  Collapse,
  styled,
} from "@mui/material";
import OpenLivestreamIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseLivestreamIcon from "@mui/icons-material/KeyboardArrowUp";

import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { useLivestreamSource } from "../Event/EventStage/useVideoSource";
import { useIsEventLive } from "../../providers/EventStateProvider";

interface Props {}

const VideoContainer = styled("div")(() => ({
  display: "flex",
  position: "relative",
  width: "100%",
  height: "100%",
}));

const PreviewNotStreaming = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: spacing(1),
  "& > div": {
    marginBottom: spacing(1),
  },
}));

const PreviewContainer = styled("div")(({ theme: { spacing, palette } }) => ({
  display: "flex",
  paddingTop: spacing(1),
  flexDirection: "column",
  borderBottom: `1px solid ${palette.divider}`,
}));

const PreviewCollapseHeader = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: spacing(1),
}));

const Preview = styled("div")(() => ({
  display: "flex",
  minHeight: 165,
  // min height fallback if browser aspect ratio doesn't work
  aspectRatio: "16/9",
}));

const CloseIconButton = styled(IconButton)(
  ({ theme: { spacing, palette } }) => ({
    color: palette.base?.[400],
    padding: spacing(1),
  }),
);

const videoJsOptions = {
  // This will allow us to get the picture in picture button in the toolbar
  controlBar: { pictureInPictureToggle: true },
};

const PreviewPlayer = ({
  onPipToggle,
}: {
  onPipToggle: (enabled: boolean) => void;
}) => {
  const isEventLive = useIsEventLive();
  const { src, isReady, reset } = useLivestreamSource();

  const isWaitingForStream = isEventLive && !isReady;
  const isStreaming = isEventLive && isReady;

  return (
    <VideoContainer
      sx={
        !isReady
          ? {
              alignItems: "center",
              justifyContent: "center",
            }
          : undefined
      }
    >
      {isStreaming && (
        <VideoPlayer
          src={src}
          autoplay
          muted
          controls
          onPictureInPictureChange={(event, player, isEnabled) =>
            onPipToggle(isEnabled)
          }
          onEnded={reset}
          onError={reset}
          videoJsOptions={videoJsOptions}
        />
      )}
      {!isStreaming && (
        <PreviewNotStreaming>
          {isWaitingForStream ? (
            <>
              <CircularProgress />
              <Typography>Waiting for livestream...</Typography>
            </>
          ) : (
            <Typography>
              You are not currently live streaming to the audience.
            </Typography>
          )}
        </PreviewNotStreaming>
      )}
    </VideoContainer>
  );
};

const EventStreamPreview = forwardRef(
  (props: Props, ref?: ForwardedRef<HTMLDivElement | null>) => {
    const isEventLive = useIsEventLive();
    const [isLivestreamOpen, setIsLivestreamOpen] = useState(isEventLive);
    const [isPip, setIsPip] = useState(false);

    useUpdateEffect(() => {
      // When the event live status changes open/close the view
      setIsLivestreamOpen(isEventLive);
    }, [isEventLive]);

    const onPipToggle = useCallback((enabled: boolean) => {
      // auto close/open the collapse when pip changes
      setIsLivestreamOpen(!enabled);
      setIsPip(enabled);
    }, []);

    const onTogglePreview = useCallback(() => {
      setIsLivestreamOpen((s) => !s);
    }, []);

    return (
      <PreviewContainer ref={ref}>
        <PreviewCollapseHeader>
          <CloseIconButton
            aria-label="close preview"
            onClick={onTogglePreview}
            size="large"
          >
            {isLivestreamOpen ? (
              <CloseLivestreamIcon />
            ) : (
              <OpenLivestreamIcon />
            )}
          </CloseIconButton>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
            noWrap
          >
            Audience View
          </Typography>
        </PreviewCollapseHeader>
        {/* Keep in DOM if pip is enabled so video stream doesn't stop while pip'd */}
        <Collapse in={isLivestreamOpen} unmountOnExit={!isPip}>
          <Preview>
            <PreviewPlayer onPipToggle={onPipToggle} />
          </Preview>
        </Collapse>
      </PreviewContainer>
    );
  },
);

export default memo(EventStreamPreview);
