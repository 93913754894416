import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAsync, usePrevious } from "react-use";
import { VideoType } from "../../../models/eventType";
import { useGetVodPath } from "../../../providers/EventReplaysProvider";
import { useEvent } from "../../../providers/EventProvider";
import { DEFAULT_STREAM_PATH } from "../../../providers/EventReplaysProvider";
import { Event } from "../../../contracts/event/event";

const getStreamPath = (event: Event) => {
  let baseStreamPath = DEFAULT_STREAM_PATH;
  let streamPath = event.streamInfo.streamPath || baseStreamPath;

  if (event.ivsStreamInfo) {
    streamPath = event.ivsStreamInfo.streamPath;
  }

  if (event.streamInfo.streamPath) {
    streamPath =
      event.streamInfo.streamPath + event.streamInfo.streamId + ".m3u8";
  }

  return streamPath;
};

const isSourceAvailable = async (
  src: string,
  fetchOptions: RequestInit = {},
) => {
  try {
    const result = await fetch(src, {
      method: "HEAD", // only check if the content is available and don't download video file
      ...fetchOptions,
    });
    if (result.status === 200) {
      return true;
    }
  } catch (err) {
    // ignore
  }
  return false;
};

export const useLivestreamSource = ({
  disabled,
}: { disabled?: boolean } = {}) => {
  const { data: event } = useEvent();
  const [isLoading, setIsLoading] = useState(false);
  const intervalRef = useRef<number>();
  const [src, setSrc] = useState("");

  const streamPath = useMemo(
    () => (event ? getStreamPath(event) : undefined),
    [event],
  );

  useAsync(async () => {
    if (!streamPath || disabled || src) return;
    const getStream = async () => {
      const isAvailable = await isSourceAvailable(streamPath);
      if (isAvailable) {
        typeof intervalRef.current !== "undefined" &&
          clearInterval(intervalRef.current);
        setSrc(streamPath);
        setIsLoading(false);
        return true;
      }
    };
    // initially try to get the stream
    const result = await getStream();
    if (result) return;

    // Set an interval to attempt to get a stream
    intervalRef.current = setInterval(getStream, 2000) as unknown as number;
    setIsLoading(true);
    return () => {
      clearInterval(intervalRef.current);
      setIsLoading(false);
    };
  }, [streamPath, disabled, src]);

  const reset = useCallback(() => {
    setSrc("");
  }, []);

  // If the streamPath changes, reset to poll for new stream
  useEffect(() => {
    if (src && streamPath !== src) setSrc("");
  }, [src, streamPath]);

  // on unmount clear any intervals
  useEffect(() => () => clearInterval(intervalRef.current), []);

  return {
    src,
    isReady: !!src,
    isLoading,
    reset,
  };
};

export const useReplaySource = ({ disabled }: { disabled?: boolean } = {}) => {
  const [src, setSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const intervalRef = useRef<number>();
  const vodPath = useGetVodPath();
  const cdnPath = useGetVodPath(true);

  useAsync(async () => {
    if ((!vodPath && !cdnPath) || disabled || src) return;
    if (vodPath.includes("master.m3u8")) {
      setSrc(vodPath);
      setIsLoading(false);
    }

    const getStream = async () => {
      const cdnAvailable = await isSourceAvailable(
        `${cdnPath}?_t=${Date.now()}`,
        {
          mode: "cors",
        },
      );
      if (cdnAvailable) {
        clearInterval(intervalRef.current);
        setSrc(cdnPath);
        setIsLoading(false);
        return true;
      }
      // If we didn't get the replay, try the vod
      const vodAvailable = await isSourceAvailable(
        `${vodPath}?_t=${Date.now()}`,
        {
          mode: "cors",
        },
      );
      if (vodAvailable) {
        clearInterval(intervalRef.current);
        setSrc(vodPath);
        setIsLoading(false);
        return true;
      }
    };
    // initially try to get the stream
    const result = await getStream();
    if (result) return;

    // Set an interval to attempt to get a stream
    intervalRef.current = setInterval(getStream, 5000) as unknown as number;
    setIsLoading(true);
    return () => {
      //
      clearInterval(intervalRef.current);
      setIsLoading(false);
    };
  }, [vodPath, cdnPath, disabled, src]);

  const reset = useCallback(() => {
    setSrc("");
  }, []);

  // If the VOD or cdn change then reset to get latest
  useEffect(() => {
    if (src && ![vodPath, cdnPath].includes(src)) setSrc("");
  }, [src, vodPath, cdnPath]);

  // on unmount clear any intervals
  useEffect(() => () => clearInterval(intervalRef.current), []);

  return {
    src: src,
    isReady: !!src,
    isLoading,
    reset,
  };
};

/**
 * Hook used for getting the video URL source based on the supplied `videoType`.
 *
 * Will return the `src` for the stream once it has been verified as a valid source.
 *
 * Use the `reset` function to reload the media and poll again if necessary.
 */
export const useVideoSource = ({
  videoType,
  disabled,
}: {
  videoType: VideoType;
  disabled?: boolean;
}): {
  src: string;
  isReady: boolean;
  isLoading: boolean;
  reset: () => void;
} => {
  const previousType = usePrevious(videoType);
  const {
    src: livestreamSrc,
    reset: resetLive,
    isLoading: isLiveLoading,
    isReady: isLiveReady,
  } = useLivestreamSource({
    disabled: disabled || videoType !== VideoType.Live,
  });

  const {
    src: replaySrc,
    reset: resetReplay,
    isLoading: isReplayLoading,
    isReady: isReplayReady,
  } = useReplaySource({
    disabled: disabled || videoType === VideoType.Live,
  });

  const reset = useCallback(() => {
    resetLive();
    resetReplay();
  }, [resetLive, resetReplay]);

  useEffect(() => {
    if (videoType !== previousType) {
      reset();
    }
  }, [videoType, reset, previousType]);

  return {
    src: livestreamSrc || replaySrc,
    isReady: !disabled && (isReplayReady || isLiveReady),
    isLoading: !disabled && (isLiveLoading || isReplayLoading),
    reset,
  };
};
